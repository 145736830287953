import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Home/home';
import HamburgerMenu from './components/NavBar/hamburgerMenu';
import Menu from './components/Menu/menu';
import About from './components/About/about';
import Posts from './components/Posts/posts';
import './scss/home.css';

function App() {
  return (
    <Router>
      <div>
        {/* navbar will be here for now idk if its worth making it into a component */}
        <nav className='webnav'>
          <h2>BorderTown Mexican Grill</h2>
          <div className='links'>
            <HamburgerMenu/>
          </div>
        </nav>

        <Switch>
          <Route exact path="/">
            <Home/>
          </Route>
          <Route path="/about">
            <About/>
          </Route>
          <Route path="/menu">
            <Menu/>
          </Route>
          <Route exact path="/posts">
            <Posts/>
          </Route>
        </Switch>
      </div>
    </Router>
  );

}

export default App;
