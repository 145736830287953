import React from 'react';
//CSS FROM SASS
import '../../scss/about.css';

function About() {
    return (
        <section className='about'>
            <h2>About</h2>
            <div>
                <h3>Contact</h3>
                <p>(360) 441-7661</p>
            </div>
            <div>
                <h2>Buisness Hours</h2>
                    <p>
                    Mon: 8:00 AM – 8:00 PM<br/>
                    Tue: 8:00 AM – 8:00 PM<br/>
                    Wed: 8:00 AM – 8:00 PM<br/>
                    Thu: 8:00 AM – 8:00 PM<br/>
                    Fri: 8:00 AM – 8:00 PM<br/>
                    Sat: 8:00 AM – 8:00 PM<br/>
                    Sun: 10:00 AM – 7:00 PM<br/>
                    </p>
            </div>
        </section>
    )
}

export default About;