import React from 'react';
import Footer from './Footer';
//photos for 3 paragraphs
import tacos from '../../Pictures/Tacos3.jpg';
import suprememBurrito from '../../Pictures/SupremeBurrito3.jpg';
import mole from '../../Pictures/Mole3.jpg';

function Home() {
    return(
        <section>
            <div className='main_photo'>
                <div className='title_container'>
                    <div id="name_text">
                        <div>THE BMG</div>
                        <p>THE BEST BURRITOS IN TOWN</p>
                    </div>
                </div>
            </div>
            <div className='feature_items'>
                <div className='f_item'>
                    <div className='div_item1'>
                        <h2 id='header_1'>Welcome:</h2>
                        <p id='item_para1'>Get ready for your next trip to an authentic Mexican restaurant!</p>
                    </div>
                    <img className='item_pho' src={tacos} alt='Tacos'/>
                </div>
                <div id='f_item2' className='f_item'>
                    <img className='item_pho2' src={suprememBurrito} alt='Supreme Burrito'/>
                    <div className='div_item2'>
                        <h2 id='header_2'>BorderTown Mexican Grill</h2>
                        <p id='item_para2'>BorderTown Mexican Grill features authentic Mexican food in a open-style atmosphere. Located at 538 Peace Portal Dr in Blaine Washington.</p>
                    </div>
                </div>
                <div className='f_item'>
                    <div className='div_item3'>
                        <h2 id='header_3'>Carry out or Dine in</h2>
                        <p id='item_para3'>Hello everyone, Bordertown Mexican Grill is open. You can call in your orders at <a href='tel:(360) 441-7661'>(360)441-7661</a>. Thank you so much</p>
                    </div>
                    <img className='item_pho' src={mole} alt='Mole'/>
                </div>
            </div>
            <Footer/>
        </section>
    )
}

export default Home;