import React from 'react';

const PostCard = prop => {
    return(
        <div className='postcards'>
            <h3>{prop.header}</h3>
            <p>{prop.update_post}</p>
        </div>
    )
}

export default PostCard;