import React from 'react';

function CardMenu (props) {
    // console.log(props, 'this is props')
    return(
    props.menu_food.map(e => {
        // console.log(e.img, 'this');
        return(
            <div className='item_name'>
                <img className='item_image' src={e.img} alt={e.name}/>
                <h4>{e.name}</h4>
                <div>{e.description}</div>
            </div>
        )
    }))
}

export default CardMenu;