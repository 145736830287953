import React from 'react';
// import DirectionPhoto from '../../Pictures/directionsMap.png';

function Footer() {
    return (
        <div className='footer'>
            <section>
                <div className='contact_sub'>
                    <h2>Contact</h2>
                    <p className='contact_btn'>Call us at:</p>
                    <a href='tel:(360) 441-7661'>(360) 441-7661</a>
                </div>
            {/* </section>
            <section> */}
                <div className='contact_sub'>
                    <h2>Address</h2>
                    <a className='contact_btn' href="https://www.google.com/maps/dir//Bordertown+Mexican+Grill/data=!4m8!4m7!1m0!1m5!1m1!1s0x5485c3ff9716d979:0xce3dd8301e63fcd1!2m2!1d-122.7515955!2d48.995679599999995">Get Directions</a>
                    <p>538 Peace Portal Dr</p>
                    <p>Blaine, WA 98230</p>
                    <p>United States</p>
                </div>
            {/* </section>
            <section> */}
                <div className='contact_sub'>
                    <h2>Buisness Hours</h2>
                        {/* <ul style={{listStyleType: 'None'}}> */}
                        <p>
                            Mon: 8:00 AM – 8:00 PM<br/>
                            Tue: 8:00 AM – 8:00 PM<br/>
                            Wed: 8:00 AM – 8:00 PM<br/>
                            Thu: 8:00 AM – 8:00 PM<br/>
                            Fri: 8:00 AM – 8:00 PM<br/>
                            Sat: 8:00 AM – 8:00 PM<br/>
                            Sun: 10:00 AM – 7:00 PM<br/>
                        </p>
                            {/* <li>Mon: 8:00 AM – 8:00 PM</li>
                            <li>Tue: 8:00 AM – 8:00 PM</li>
                            <li>Wed: 8:00 AM – 8:00 PM</li>
                            <li>Thu: 8:00 AM – 8:00 PM</li>
                            <li>Fri: 8:00 AM – 8:00 PM</li>
                            <li>Sat: 8:00 AM – 8:00 PM</li>
                            <li>Sun: 10:00 AM – 6:00 PM</li> */}
                        {/* </ul> */}
                </div>
            </section>
            <div id='footer_bottom'>
            <div>Copyright © Bordertown Mexican Grill</div>
            <div>Web design by <a href='https://martinezhector.com/'>Hector Martinez</a></div>
            </div>
        </div>
    )
}

export default Footer;