import React from 'react';

function MenuSide (props) {
    // console.log(props, 'this is props')
    return(
    props.menu_sides.map(e => {
        // console.log(e.img, 'this');
        return(
            <div className='item_name'>
                <img className='item_image' src={e.img} alt={e.alternative}/>
                <h4>{e.name}</h4>
            </div>
        )
    }))
}

export default MenuSide;