import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PostCard from './postCard';
//CSS FROM SASS
import '../../scss/posts.css';

function Posts() {
    const [post, setPost] = useState([]);
    // let postInfo = []

    useEffect(() => {
        axios
        .get('https://bordertown-api.herokuapp.com/posts/all')
        .then(res => {
            console.log(res.data, "RES THEN DATA")
            setPost(res.data)
            // let postInfo = [];
            // res.data.map(e => {
            //     return postInfo = (e);
            // });
            // setPost(postInfo);
        })
        .catch(err => {
            console.log('error with getting posts', err)
        })
    },[]);

    return (
        <section className='Posts'>
            <h2>Posts</h2>
            <div className='postcards_container'>
                {post.map(item => {
                    console.log(item, 'ITEM')
                    return(
                        <PostCard header={item.header} update_post={item.update_post}/>
                    )
                })}
            </div>
        </section>
    )
}

export default Posts;