import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function HamburgerMenu(){
    const [sidebar, setSidebar] = useState(false);
    //remember to add a useEffect if this works
    const handleOpen = () => {
        console.log('sidebar is open')
        setSidebar(true)
    };

    const handleClose = () => {
        console.log('sidebar is closed')
        setSidebar(false)
    }

    if(sidebar === true){
        return(
            <div className='sidebar_container'>
                <div className='close_button_con'>
                    <div className='close_button' onClick={handleClose}></div>
                </div>
                {/* <div className='close_button' onClick={handleClose}></div> */}
                <div className='sidebar'>
                    <Link className='sidebar_link' to="/" onClick={handleClose}>Home</Link>
                    <Link className='sidebar_link' to="/menu" onClick={handleClose}>Menu</Link>
                    <Link className='sidebar_link' to="/about" onClick={handleClose}>About</Link>
                    {/* <Link className='sidebar_link' to="/posts" onClick={handleClose}>Posts</Link> */}
                </div>
            </div>
        )
    }

    return(
        <div className='sidebar_container'>
            <div className="hamburger_icon" onClick={handleOpen}></div>
        </div>
    )
}

export default HamburgerMenu;