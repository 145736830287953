import React from 'react';

function MenuDrink (props) {
    // console.log(props, 'this is props')
    return(
    props.menu_drinks.map(e => {
        // console.log(e.img, 'this');
        return(
            <div className='item_name'>
                {/* <img className='item_image' src={e.img}></img> */}
                <h4>{e.name}</h4>
                {/* <div>{e.description}</div> */}
            </div>
        )
    }))
}

export default MenuDrink;