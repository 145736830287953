import React from 'react';
import MenuCard from './menu_card';
import MenuDrink from './menu_drink';
import MenuSide from './menu_side';
import '../../scss/menu.css'
//stand in photos add real pictures later
import regularBurrito from '../../Pictures/RegularBurrito3.jpg';
import threeTacoPlate from '../../Pictures/3TacoPlate3.jpg';
import enchiladas from '../../Pictures/Enchiladas3.jpg';
import fishBurrito from '../../Pictures/FishBurrito3.jpg';
import fishTaco from '../../Pictures/FishTaco3.jpg';
import mole from '../../Pictures/Mole3.jpg';
import nachos from '../../Pictures/Nachos3.jpg';
import porkChileVerde from '../../Pictures/PorkChileVerde3.jpg';
import quesadilla from '../../Pictures/Quesadilla3.jpg';
import salad from '../../Pictures/Salad3.jpg';
import tacos from '../../Pictures/Tacos3.jpg';
import tamales from '../../Pictures/Tamales3.jpg';
import taquitosDorados from '../../Pictures/TaquitosDorados3.jpg';
import torta from '../../Pictures/Torta3.jpg';
import tostada from '../../Pictures/Tostada3.jpg';
import veggieBurritoSupreme from '../../Pictures/VeggieBurrito_supreme3.jpg';
import veggieTacoPlate from '../../Pictures/VeggieTacoPlate3.jpg';
import birria from '../../Pictures/Birria3.jpg';
import chileRelleno from '../../Pictures/ChileRelleno3.jpg';
import chipsAndGuac from '../../Pictures/ChipsAndGuac3.jpg';
import chipsAndPico from '../../Pictures/ChipsAndPico3.jpg';
import chorizoBreakfastPlate from '../../Pictures/ChorizoBreakfastPlate3.jpg';
import sopes from '../../Pictures/Sopes3.jpg';
import supremeBurrito from '../../Pictures/SupremeBurrito3.jpg';
import photonotavailable from '../../Pictures/photoNotAvailable.jpg';
//components (footer)
import Footer from '../Home/Footer';

let menu_food = [
    {name: 'Supreme Burrito',
    img: supremeBurrito, 
    description: 'Supreme Burrito: Choice of meat, beans, rice, salsa, onion, cilantro, tommato, lettuce, cheese, and sour cream'},
    {name: 'Regular Burrito',
    img: regularBurrito,
    description: 'Choice of meat, beans, rice, salsa, onion, & cilantro'},
    {name: 'Fish Burrito',
    img: fishBurrito,
    description: 'Grilled fish, rice, beans, cheese, sour cream, pico, lettuce, and salse'},
    {name: 'Steak Breakfast Burrito',
    img: photonotavailable, 
    description: 'Steak, egg, potatoe, bell pepper, cheese, and salsa'},
    {name: 'Chorizo Breakfast Burrito',
    img: photonotavailable,
    description: 'Chorizo, egg, potatoe, cheese and salsa'},
    {name: 'Tacos',
    img: tacos,
    description: 'Choice of meat, onion, cilantro, & lime'},
    {name: 'Fish Taco',
    img: fishTaco,
    description: 'Grilled fish, sour cream, lettuce, & pico'},
    {name: 'Taco Plate',
    img: threeTacoPlate,
    description: 'Three Tacos served with a side of rice and whole beans'},
    {name: 'Nachos',
    img: nachos,
    description: 'Choice of meat, refried beans, cheese, pico, tomato, jalapenos, and sour cream'},
    {name: 'Salad',
    img: salad,
    description: 'Choice of meat, lettuce, pico, cheese, avocado, chips, served with a side of rice and beans'},
    {name: 'Quesadilla',
    img: quesadilla,
    description: 'Choice of meat, served with a side of pico and sour cream'},
    {name: 'Enchiladas Chicken',
    img: enchiladas,
    description: 'Three chicken enchiladas served with a side of rice and beans'},
    // {name: 'Meat plate',
    // img: standInPhoto,
    // description: 'Choice of meat, served with a side of rice, beans, and 5 tortillas'},
    {name: 'Taquitos Dorados Plate',
    img: taquitosDorados,
    description: 'Crispy chicken tacos served with a side of rice and beans'},
    {name: 'Tamales',
    img: tamales,
    description: 'Chicken or pork tamales, made with corn flour Masa(Dough)'},
    {name: 'Tostada',
    img: tostada,
    description: 'Choice of meat, refried beans, cheese, lettuce, pico, and sour cream'},
    {name: 'Torta',
    img: torta,
    description: 'Choice of meat, refried beans, onions, cilantro, tomato, jalapeno, avocado, and lettuce'},
    {name: 'Veggie Burrito',
    img: veggieBurritoSupreme,
    description: 'Beans, rice, pico, lettuce, avocado, and salsa'},
    {name: 'Veggie Supreme',
    img: veggieBurritoSupreme,
    description: 'Beans, rice, pico, lettuce, pico, avocado, and sour cream'},
    {name: 'Veggie Tostada',
    img: photonotavailable,
    description: 'Refried beans, lettuce, pico, avocado, and sour cream'},
    {name: 'Veggie Nachos',
    img: photonotavailable,
    description: 'Refried beans, lettuce, pico, and avocado (choice of cheese and sour cream)'},
    {name: 'Veggie Taco',
    img: photonotavailable,
    description: 'Rice, beans, lettuce, pico, and avocado (choice of cheese and sour cream)'},
    {name: 'Veggie Taco Plate',
    img: veggieTacoPlate,
    description: 'Three veggie tacos served with a side of rice and beans (choice of cheese and sour cream)'},
    {name: 'Veggie Torta',
    img: photonotavailable,
    description: 'Refried beans, lettuce, tomato, jalapeno, onion, cilantro, and avocado'},
    {name: 'Mole',
    img: mole,
    description: 'Mole with chicken, served with a side of rice & tortillas'},
    {name: 'Chile Verde',
    img: porkChileVerde,
    description: 'Pork in green sauce, served with a side of rice, beans & 5 tortillas'},
    {name: 'Birria',
    img: birria,
    description: 'Beef stew, served with a side of rice, beans, and 5 tortillas'},
    {name: 'Sopes',
    img: sopes,
    description: 'Refried beans, cheese, choice of meat, lettuce, and pico'},
    {name: 'Chorizo Breakfast Plate',
    img: chorizoBreakfastPlate,
    description: 'Choice of meat, onion, cilantro, & lime'},
    {name: 'Chile Relleno',
    img: chileRelleno,
    description: 'Chile Relleno cheese filling, served with a side of rice, beans, and 5 tortillas'}
];

let menu_sides = [
    {name: 'Rice'},
    {name: 'Beans'},
    {name: 'Pico de gallo'},
    {name: 'Chips & Pico',
    img: chipsAndPico,
    alternative: 'Chips & Pico'},
    {name: 'Chips & Guac',
    img: chipsAndGuac,
    alternative: 'Chips & Guac'}
]

let menu_drinks = [
    {name: 'Jarritos'},
    {name: 'Mexican Coke'},
    {name: 'Coke Can'},
    {name: 'Sprite Can'},
    {name: 'Horchata'},
    {name: 'Tamarindo'},
    {name: 'Jamaica'},
    {name: 'Water'}
];

function Menu() {
    return (
        <section>
            <div className='menu'>
                <h2>Menu</h2>
                <div className='menu_food'>
                    <MenuCard menu_food={menu_food}/>
                </div>
                <h2>Sides</h2>
                <div className='menu_food'>
                <MenuSide menu_sides={menu_sides}/>
                </div>
                <h2>Drinks</h2>
                <div className='menu_food'>
                <MenuDrink menu_drinks={menu_drinks}/>
                </div>
            </div>
            <Footer/>
        </section>
    )
}

export default Menu;

